<template>
    <router-view :class="{unauthorized: unauthorized, loading: portalStore.loading}" />
    <notificationElement />
</template>

<script>
import { defineComponent, onMounted, watch, reactive, computed, provide } from "vue";

import notificationElement from "components/notifications";

import {init, unauthorized, portalStore} from "store/portal";

// Our styles
import styles from 'css/app.scss';

export default defineComponent({
    name: "app",
    props: {
        application : {
            type: String,
            required: false,
            default: "workflowportal"
        },
        portal_url: { // Used to pass correct portal url from community to portal code
            type: String,
            required: false,
            default: ""
        }
    },
    components: {
        notificationElement
    },
    setup(props) {
        const portal_url = computed(() => {
            return props.portal_url.replace("http://", "//");
        });
        const application = computed(() => {
            return props.application;
        });
        const isWorkflowportal = computed(() => {
            return props.application === "workflowportal";
        });
        const isCommunity = computed(() => {
            return props.application === "community";
        });

        console.log("portal_url",portal_url.value);
        console.log("application",application.value);

        provide("portal_url", portal_url);
        provide("application", application);
        provide("isWorkflowportal", isWorkflowportal);
        provide("isCommunity", isCommunity);

        // NOTE: How does Portal work?
        // 1. We have a 'user' within workflowmanager wingzz (for now) or within auth.ibizz.nl (later)
        //    The user allows us to login
        // 2. Within workflowmanager we have a contact or user board.
        //    This board is a list of contacts or users as workflowcards.
        //    Within our public/env/...json we define which workflowcardtype_id is used for contacts.
        //    The contact is related with the generic link tabel to the 'wingzz' user.
        // 3. Within public/env/...json we define our properties related to contact.
        //    So for example; "fullname": ["gebruiker_voornaam", "gebruiker_achternaam"],
        //    Which defines fullname exists out of gebruiker_voornaam and gebruiker_achternaam.

        onMounted(init);

        return {
            unauthorized,
            portalStore
        }
    }
});
</script>