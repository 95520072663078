<template>
    <template v-if="currentLayout == 'list'">
        <div v-if="this.first" class="Block Workflowcard Item heading">
            <div class="th-state"></div>
            <div class="th-title" :class="{ hassort: isSortingColumn('title') }" @click="setSortingColumn('title')">
                {{ trans("Titel") }}
                <button v-if="isSortingColumn('title')" class="sorting" :class="{ active: isSortingColumn('title') }">
                    <Icon :name="`sort-${getSortingOrder()}`" />
                </button>
            </div>
            <template v-for="propertyfield in componentData.workflowlayout">
                <div class="th-spec" :class="{ hassort: isSortingColumn(propertyfield.property.id) }" @click="setSortingColumn(propertyfield.property.id)">
                    {{ propertyfield.label }}
                    <button v-if="isSortingColumn(propertyfield.property.id)" class="sorting" :class="{ active: isSortingColumn(propertyfield.property.id) }">
                        <Icon :name="`sort-${getSortingOrder()}`" />
                    </button>
                </div>
            </template>
        </div>
        <div class="Block Workflowcard Item list colored bgwhite" :class="{ linkdiv : true, useHand : true }" @click="handleClick($event,data.id)">
            <div class="td-state">
                <state v-if="data.priority" :value="data.priority" />
            </div>
            <div class="td-title">
                <h3 class="h3" v-if="workflowboardId"><cardtitle v-if="data" :data="data" :workflowboard_id="workflowboardId" /></h3>
            </div>
            <template v-for="propertyfield in componentData.workflowlayout">
                <div class="td-spec" :class="`td-${getAccessString(propertyfield.label)}`" :set="property = resolveProperty(data, propertyfield.property.id)">
                    <var v-if="property" :title="property?.value">
                        <template v-if="resolvePropertyType(property) == 'CardField'">
                            <cardtitle v-if="property.value" :id="property.value" />
                        </template>
                        <template v-else-if="resolvePropertyType(property) == 'UserField'">
                            <carduser v-if="property.value" :id="property.value" />
                        </template>
                        <template v-else-if="resolvePropertyType(property) == 'CheckboxField'">
                            {{ formatActives(property.value) }}
                        </template>
                        <template v-else>
                            {{ property.value }}
                        </template>
                    </var>
                </div>
            </template>
        </div>
    </template>
    <template v-else>
        <article class="Block Workflowcard Item default colored bgwhite linkdiv useHand" @click="handleClick($event,data.id)">
            <div class="inner">
                <state v-if="data.priority" :value="data.priority" />
                <h3 class="h3" v-if="workflowboardId"><cardtitle v-if="data" :data="data" :workflowboard_id="workflowboardId" /></h3>
                <ul class="block-grid one-up specs" :key="Object.keys(componentData.properties).length">
                    <template v-for="propertyfield in componentData.workflowlayout">
                        <li v-if="resolveProperty(data, propertyfield.property.id)" :set="property = resolveProperty(data, propertyfield.property.id)">
                            <label>
                                {{ propertyfield.label }}
                            </label>
                            <template v-if="resolvePropertyType(property) == 'CardField'">
                                <var><cardtitle v-if="property.value" :id="property.value" /></var>
                            </template>
                            <template v-else-if="resolvePropertyType(property) == 'UserField'">
                                <var><carduser v-if="property.value" :id="property.value" /></var>
                            </template>
                            <template v-else-if="resolvePropertyType(property) == 'CheckboxField'">
                                <var :title="formatActives(property.value)">
                                    {{ formatActives(property.value) }}
                                </var>
                            </template>
                            <template v-else>
                                <var :title="property.value">
                                    {{ property.value }}
                                </var>
                            </template>
                        </li>
                    </template>
                </ul>
            </div>
        </article>
    </template>
</template>

<script>
import {defineComponent, computed, onMounted, reactive, ref, inject, watch } from "vue";
import {useRouter} from "vue-router";

// Helper functions
import { formatActives } from "helpers/_formatting";
import trans from "helpers/_translation";

import { item } from "store/portal";
import { isSortingColumn, setSortingColumn, getSortingOrder, getSortingColumn } from "store/sorting";
import { workflowlayout, resolvePropertyType, resolveProperty } from "store/workflow";

// Our components
import Icon from "components/icon";

import State from "components/workflowcard/state";
import cardlink from "components/workflowcard/cardlink";
import carduser from "components/workflowcard/carduser";
import cardtitle from "components/workflowcard/cardtitle";


// Config for API urls used in this vue template
import { config } from "config";

export default defineComponent({
    name: "workflowcard-item",
    components: {
        State,
        cardlink,
        carduser,
        cardtitle,
        Icon
    },
    props: {
        first: {
            type: Boolean,
            required: false,
            default: false
        },
        data: {
            type: Object,
            required: true
        },

        layout: {
            type: String,
            required: false,
            default: "list"
        },
        workflowphase_id: {
            type: String,
            required: false
        },
        workflowboard_id: {
            type: String,
            required: false
        }
    },
    setup(props, { emit }) {
        const viewData = inject("viewData");
        const workflowboardId = computed(() => props.workflowboard_id);

        // Our configuration and constants
        const currentLayout = ref(props.layout);

        // Our component specific data
        const componentData = reactive({
            properties: computed(() => props.data.extra.workflowproperties),
            workflowlayout: computed(() => workflowlayout(props.workflowboard_id, "overview"))
        });

        // Events
        const handleItemClick = inject("handleItemClick");
        const handleClick = (e, id) => {
            // if (props.related) return;
            // let url = `/workflowboard/${props.workflowboard_id}/workflowcard/${id}`;
            // router.push(url);
            // // window.location = url;
            const params = {
                workflowboard_id: `${props.workflowboard_id}`,
                workflowphase_id: `${props.workflowphase_id}`
            };
            if (viewData.workflowcard_id) {
                params.workflowcard_parent_id = `${viewData.workflowcard_id}`;
                params.workflowcard_id = `${id}`;
            } else {
                params.workflowcard_id = `${id}`;
            }
            console.log("params",params);

            handleItemClick(e, params);
        };

        const getAccessString = (string) => {
            return String(string.toLowerCase()).replace(" ", "_").replace(",", "");
        };

        return {
            trans,
            currentLayout,
            componentData,
            handleClick,

            resolveProperty,
            workflowboardId,

            getAccessString,
            resolvePropertyType,

            isSortingColumn,
            setSortingColumn,
            getSortingOrder,
            getSortingColumn,

            formatActives
        };
    },
});
</script>


