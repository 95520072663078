
<template>
    <div class="Heading">
        <div class="left">
            <h1 class="h1">
                <span class="main" @click.prevent="goBack()">
                    <Icon v-if="viewData.workflowcard_id" name="arrow" />
                    {{ trans(viewData.title) }}
                </span>
                <span class="card" v-if="viewData.workflowcard_id && !viewData.config.settings?.target?.workflowcard_id">
                    <cardTitle v-if="viewData.item.data" :data="viewData.item.data" :key="viewData.workflowcard_id" />
                </span>
            </h1>
        </div>
        <div class="right">
            <nav id="navigationSpot">
                <button v-if="tab_exists('extra', 'reactions') && !isCommunity" :disabled="tab_active('extra', 'reactions')" @click.prevent="handleTab('extra', 'reactions')" type="button" class="more outline">
                    <span class="title">{{ trans("Plaats opmerking") }}</span>
                </button>
                <Actions v-if="viewData.workflowcard_id" :key="viewData.workflowphase_id" />

                <WorkflowcardAdd v-if="!viewData.workflowcard_id" :workflowboard_id="viewData.workflowboard_id" />
            </nav>
        </div>
    </div>
</template>

<script>
import { defineComponent, inject } from "vue";
import { useRoute, useRouter } from "vue-router";

import Actions from "components/workflowcard/actions";
import Icon from "components/icon";
import trans from "helpers/_translation";

import cardTitle from "components/workflowcard/cardtitle";

import WorkflowcardAdd from "view/dashboard/includes/workflowcard-add";

export default defineComponent({
    name: "part",
    components: {
        Icon,
        Actions,
        cardTitle,
        WorkflowcardAdd
    },
    setup() {
        const router = useRouter();
        const viewData = inject("viewData");
        const isCommunity = inject("isCommunity");

        const handleTab = inject("handleTab");
        const tab_active = inject("tab_active");
        const tab_exists = inject("tab_exists");

        const goBack = () => {
            if (!viewData.workflowcard_id) return;
            router.back();
        };

        return {
            trans,
            viewData,

            handleTab,
            tab_active,
            tab_exists,

            isCommunity,

            goBack
        }
    }
});
</script>
