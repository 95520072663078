<template>
    <div class="element" :data-required="this.required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <input
            type="number"
            min="0"
            max="100"
            step="1"
            pattern="[0-9]"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
            :autocomplete="this.autocomplete"
            :maxlength="this.maxlength"
            v-model="input"
            @keypress="isNumber"
            @keyup="changeHandler($event) && validate($event)"
            @blur="validate"
        />
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {ref, watch} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();
        registerValidator(input, props);

        const isNumber = (e) => {
            e = e ? e : window.event;
            const charCode = (e.which) ? e.which : e.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
                e.preventDefault();
            } else {
                return true;
            }
        };

        const changeHandler = (e) => {
            const target = e.target;
            if (Number(target.value) > 100) {
                target.value = 100
            }
        };

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        const placeholder = computed(() => props.translate ? trans(props.placeholder) : props.placeholder);

        return {
            trans,
            input,
            error,
            resolveProps,
            validate,
            isNumber,
            changeHandler,
            placeholder
        };
    },
}
</script>