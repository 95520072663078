import { ref, watch } from "vue";

import { setTranslationObject } from "helpers/_translation";

export const languageOptions = {
	"nl_NL": {
		"label": "Nederlands",
		"data": {
			"Inloggen": "Inloggen",
			"E-mailadres": "E-mailadres",
			"Wachtwoord": "Wachtwoord",
			"Nederlands": "Nederlands",
			"Engels": "Engels",
			"Wachtwoord vergeten" : "Wachtwoord vergeten",
			"Wachtwoord aanvragen" : "Wachtwoord aanvragen"
		}
	},
	"en_GB": {
		"label": "Engels",
		"data": {
			"Inloggen": "Login",
			"E-mailadres": "Email address",
			"Wachtwoord": "Password",
			"Nederlands": "Dutch",
			"Engels": "English",
			"Wachtwoord vergeten" : "Forgot password",
			"Wachtwoord aanvragen" : "Request password",
			"Uw inloggegevens zijn mogelijk incorrect." : "Your login details may be incorrect.",
			"Home" : "Home",
			"Afspraken" : "Appointments",
			"Facturen" : "Invoices",
			"Tickets" : "Tickets",
			"Diensten" : "Services",
			"Content" : "Content",
			"Overzicht" : "Overview",
			"Instellingen" : "Settings",
			"Donkere modus" : "Dark mode",
			"Lichte modus" : "Light mode",
			"Uitloggen" : "Log out",
			"titel": "title",
			"Organisatie": "Organisation",
			"Deadline bundel akkoord": "Deadline bundle agreement",
			"Deadline publicatie": "Deadline publication",
			"Briefing": "Briefing",
			"Verantwoordelijke content": "Responsible for content",
			"Verantwoordelijke design": "Responsible for design",
			"Opslaan": "Save",
			"Te controleren": "To be checked",
			"Ingepland": "Scheduled",
			"Historie": "History",
			"Terug naar vorige pagina": "Back to previous page",

			"Content controleren": "Check content",
			"Content historie": "Content history",
			"Ingeplande content": "Scheduled content",
			"Titel": "Title",
			"Klant": "Customer",
			"Deadline productie": "Production deadline",
			"Opmerkingen": "Comments",
			"Versturen": "Send",
			"Goedgekeurd": "Approved",
			"Correcties door laten voeren": "Have corrections carried out",
			"Plaats opmerking": "Add comment",
			"Een nieuw bericht typen ..": "Type a new message ...",

			"Contact opnemen": "Get in touch",
			"Hoe kunnen we je helpen?" : "How can we help you?",
			"Mijn profiel" : "My profile",
			"Gegevens" : "Datails",
			"Taken": "Tasks",
			"Voornaam" : "First name",
			"Achternaam" : "Last name",

			"Welkom": "Welcome",
			"Profiel": "Profile",
			"Klanten": "Customers",
			"%s bewerken": "Edit %s",

			"Bellen": "Call",
			"Mailen": "Email",

			"Projecten": "Projects",
			"Salesprojecten": "Sales projects",
			"Contactpersonen": "Contacts",
			"Organisaties": "Organisations",
			"Feedback": "Feedback",
			"Klachten": "Complaints",
			"Toewijzen": "Assign",
			"Wilt u deze actie uitvoeren?": "Do you want to perform this action?",
			"Ja": "Yes",
			"Annuleren": "Cancel",
			"- Maak uw keuze -": "- Make your choice -",
			"Maak uw keuze": "Make your choice",
			"Maak uw keuze%s": "Make your choice%s",
			"Geen resultaten gevonden": "No results found",
			"item": "item",

			"Iemand heeft een opmerking geplaatst.": "Someone has placed a comment.",
			"#fullname# heeft een opmerking geplaatst." : "#fullname# has placed a comment.",
			"Dit item is bewerkt": "This item has been edited",
			"#fullname# heeft de actie “#action#” uitgevoerd." : "#fullname# has performed the action “#action#”.",
			"Uw opmerking is geplaatst." : "Your comment has been placed.",

			"Selecteer een ": "Select a ",
			"Selecteer een %s": "Select a %s",
			"Selecteer alsjeblieft een %s van de lijst": "Please, select a %s in the list",
			"- Selecteer een %s -": "- Select a %s -",
			"Selecteer een template": "Select a template",
			"Het lijkt erop dat er geen contactpersoon is gekoppeld.": "It seems that no contact person is linked.",
			"Mocht dit probleem aanhouden, neem dan contact op met de beheerder.": "If this problem persists, please contact the administrator.",

			"Straat": "Street",
			"Huisnummer": "House number",
			"Toevoeging": "Addition",
			"Postcode": "Zipcode",
			"Plaats": "City",
			"Land": "Country",
			"Nederland": "Netherlands",
			"België": "Belgium",
			"Frankrijk": "France",
			"Duitsland": "Germany",
			"Spanje": "Spain",
			"Verenigd Koninkrijk": "United Kingdom",
			"Italië": "Italy",
			"De wijzigingen zijn succesvol opgeslagen.": "The changes have been saved successfully.",
			"Er zijn geen wijzigingen aangetroffen.": "No changes were found.",
			"Naam": "Name",

			"Aan het laden...": "Loading...",

			"Afzender": "Sender",
			"Aan" : "To",
			"Inhoud" : "Content",
			"Let op:": "Attention:",
			"Dit automatisch gegenereerde bericht maakt gebruik van berichtvariabelen. Dit zijn de codes die in de tekst worden weergegeven tussen de tekens [% … %]. Op de plek van deze codes wordt in de uitgaande mail de beschikbare informatie gevuld. Je kunt alle uitgaande mails zelf nog aanpassen of aanvullen." : "This automatically generated message uses message variables. These are the codes that are displayed in the text between the characters [% … %]. In the outgoing mail, the available information is filled in at the location of these codes. You can still adjust or supplement all outgoing emails yourself.",
			"Selecteer bijlage" : "Select attachment",
			"Verzenden" : "Send",
			"Verder zonder mail te verzenden" : "Continue without sending an email",
			"Documenten" : "Documents",
			"Momenteel zijn er geen afbeeldingen geüpload." : "Currently no images have been uploaded.",
			"Momenteel zijn er geen bestanden geüpload." : "Currently no files have been uploaded.",
			"Sleep hier je bestanden naartoe of %{browse}" : "Drag your files here or %{browse}",
			"Sleep hier je bestanden naartoe" : "Drag your files here",
			"Sleep hier je bestanden naartoe, plak of %{browse}" : "Drag your files here, paste or %{browse}",
			"Sleep hier je bestanden naartoe, plak, %{browse} of importeer vanuit" : "Drag your files here, paste, %{browse} or import from",
			"Bestand aanpassen": "Edit file",
			"Bestand aanpassen %{file}": "Edit file %{file}",
			"Bezig %{file} aan te passen": "Editing %{file}",
			"Er werden geen bestanden toegevoegd uit de lege map": "No files were added from the empty folder",
			"Coderen..." : "Encoding...",
			"Ongeldige URL: Zorg dat je een directe link naar een bestand invoert" : "Invalid URL: Make sure you enter a direct link to a file",
			"Type hier om te zoeken naar plaatjes" : "Type here to search for images",
			"Voeg URL toe om een bestand te importeren": "Add URL to import a file",
			"%{file} overschrijdt de maximaal toegelaten bestandsgrootte van %{size}": "%{file} exceeds the maximum allowed file size of %{size}",
			"Companion kan deze URL niet laden, controleer of de URL correct is" : "Companion cannot load this URL, check if the URL is correct",
			"Kon %{file} niet uploaden" : "Could not upload %{file}",
			"Bronbestand: %{name}" : "Source file: %{name}",
			"Uploaden voltooid" : "Upload completed",
			"Uploaden" : "Upload",
			"Voltooid" : "Completed",
			"Klaar" : "Done",
			"Bestand kiezen" : "Choose file",
			"Kies bestand" : "Choose file",
			"Geen bestand gekozen" : "No file chosen",
			"Succesvol verzonden" : "Successfully sent",
			"#fullname# heeft de actie “#action#” uitgevoerd." : "#fullname# has performed the action “#action#”.",
			"De actie “#action#” is uitgevoerd." : "The action “#action#” has been performed.",
			"Vandaag" : "Today",
			"Geen '%s' gekoppeld" : "No '%s' linked",
			"- Maak een keuze -" : "- Make a choice -",

			"Filters": "Filters",
			"Eerste": "First",
			"Vorige": "Previous",
			"Volgende": "Next",
			"Laatste": "Last",
			"Nog geen items toegevoegd": "No items added yet",
			"Geen resultaten gevonden": "No results found",
			"Offerte aanvragen": "Request quote",
			"Aantal": "Amount"
		}
	}
};

const currentLanguage = ref(window.localStorage.getItem("country_code") ?? process.env.VUE_APP_LANGUAGE ?? Object.keys(languageOptions)?.[0]);

export const setLanguage = (country_code) => {
	currentLanguage.value = country_code;
	window.localStorage.setItem("country_code", country_code);
};
export const getLanguage = () => {
	return currentLanguage.value;
};

watch(
	() => currentLanguage.value,
	(key) => {
		if (!key) return;
		setTranslationObject(languageOptions[key]?.data ?? Object.values(languageOptions)?.[0]?.data);
	},
	{
		immediate: true
	}
);
