<template>
    <template v-if="componentData.forcedLayout && componentData.loading">
        {{ trans("Laden...") }}
    </template>
    <template v-if="isActiveLayout('kanban') && componentData.workflowphases">
        <section class="Block Workflowphase Overview default">
            <div class="horizontal_outer">
                <div class="horizontal_inner">
                    <div class="horizontal_item" v-for="workflowphase in componentData.workflowphases">
                        <workflowphase-item :data="workflowphase" :workflowboard_id="this.workflowboard_id" />
                    </div>
                </div>
            </div>
        </section>
    </template>
    <template v-if="isActiveLayout('list') && componentData.workflowphases">
        <section class="Block Workflowphase Overview list">
            <ul class="block-grid one-up">
                <li v-for="workflowphase in componentData.workflowphases">
                    <workflowphase-item :data="workflowphase" :hide_empty="true" :workflowboard_id="this.workflowboard_id" />
                </li>
            </ul>
        </section>
    </template>
    <teleport to="#navigationSpot">
        <div class="Component WorkflowphaseLayoutToggle" v-if="!componentData.forcedLayout">
            <template v-for="layout in layoutOptions">
                <button  @click="setActiveLayout(layout)" :class="{active: isActiveLayout(layout)}">
                    <Icon :name="`grid--${layout}`" />
                </button>
            </template>
        </div>
    </teleport>
</template>

<script>

import {defineComponent, onMounted, onUnmounted, reactive, inject, watch, ref, provide, computed } from "vue";

import trans from "helpers/_translation";
import { getQuery } from "helpers/_structure";

import { fetchItem } from "store/portal";
import { getFiltersAndStore } from "store/filters";
import { getSetting, storeSetting } from "store/settings";
import { workflowphase } from "store/workflow";


// External components
import workflowphaseItem from "view/dashboard/includes/workflowphase-item";

// Config for API urls used in this vue template
// import config from "config";

import { sort } from "store/sorting";

// Components
import Icon from "components/icon";
export default defineComponent({
	name: "workflowphase-overview",
	components: {
        workflowphaseItem,
		Icon
	},
	props: {
        workflowboard_id: {
            type: String,
            required: true
        },

        simple: {
            type: Boolean,
            required: false,
            default: false
        },
		related_filter_json: {
			type: String,
			required: false,
			default: "{}"
		}
	},
	setup(props) {

        const layoutOptions = [
            "kanban",
            "list"
        ];
        const componentData = reactive({
            loading: true,
            loading_counter: 0,
            layout: props.simple ? "list" : layoutOptions[0],
            forcedLayout: !!props.simple,
            workflowphases: []
        });
        const viewData = inject("viewData");

		// Watch for data, if data is changed filter our overview based on that data
        let currentWorkflowboardId = null;
        const initData = () => {
            if (!props.workflowboard_id || currentWorkflowboardId === props.workflowboard_id) return;
            // Check if filters where set.
            currentWorkflowboardId = props.workflowboard_id;
            props.workflowboard_id && getFiltersAndStore("workflowboard", props.workflowboard_id);
            componentData.workflowphases = computed(() => workflowphase(props.workflowboard_id));
        };
		watch(
			() => props.workflowboard_id,
			initData,
			{
				immediate: true
			}
		);

        // Any layout logic
        const isActiveLayout = a => (componentData.layout == a);
        const setActiveLayout = a => ((componentData.layout = a) && storeSetting("layout", "prefered", a));
        const restoreActiveLayout = () => (componentData.layout = getSetting("layout", "prefered"));
        onMounted(() => {
            componentData.loading = true;
            componentData.loading_counter = 0;
            // Set prefered layout by default
            if (props.layout === "default") restoreActiveLayout();
        });

        const currentLayout = computed(() => componentData.layout);
        provide("currentLayout", currentLayout);

        provide("workflowphasesData", componentData);

		return {
			trans,
            sort,

            componentData,
            viewData,

			layoutOptions,
			isActiveLayout,
			setActiveLayout
		};
	},
});

</script>