import {reactive, watch} from "vue";
import { apiHostname } from "config";

import { getDataWithoutLimit } from "helpers/_api";
import { initCache } from "helpers/_cache";

import { portalStore, fetchRelated, resolveValueParts, usergroup_ids } from "store/portal";

export const generalFilters = reactive({});

// Define cachable values and set initial cache
const cacheableValues = {
	workflowusergroup: null
};
Object.keys(cacheableValues).forEach((key) => {
	initCache("filters", key, generalFilters);
});


// Based on the module_access get related Workflowfilters
const getFilters = (module_access, id) => {
	return new Promise((resolve, reject) => {
		const filters = [
			[
				"FieldFilter",
				{
					"column": "module_object",
					"value": "Workflowfilter"
				}
			]
		];
		const tmpFilters = {
			data: []
		};
		getDataWithoutLimit(`${apiHostname}/api/v1.0/${module_access}/${id}/links`, filters, tmpFilters, "data").then(() => {
			resolve(tmpFilters.data);
		});
	});
};

// Store usergroup filters based on usergroup_ids
export const getUsergroupFilters = () => {
	const usergroupPromises = [];
	usergroup_ids.value.forEach(id => {
		// @TODO pass this as extra data instead, removing the extra API calls
		usergroupPromises.push(getFilters("workflowusergroup", id));
	});
	Promise.all(usergroupPromises).then(values => {
		const filtersByUsergroup = values.map(a => a.data);
		// Making sure no relations are stored
		const filters = [].concat(...filtersByUsergroup);
		// Store our values
		generalFilters["workflowusergroup"] = filters;
	});
};

export const getFiltersAndStore = (module_access, id) => {
	return new Promise((resolve, reject) => {
		console.log("module_access",module_access,"id",id);
		if (generalFilters[module_access] && generalFilters[module_access][id]) {
			// When filters already been resolved just return them, no need to reload
			return resolve(generalFilters[module_access][id]);
		}
		// Set the defaults and resolve
		!(module_access in generalFilters) && (generalFilters[module_access] = {});
		!(id in generalFilters[module_access]) && (generalFilters[module_access][id] = {});
		generalFilters[module_access][id] = undefined;
		resolve(generalFilters[module_access][id]);
		// Now get the data
		getFilters(module_access, id).then(result => {
			generalFilters[module_access][id] = resolveFilters(result);
		});
	});
};
// Resolve filters, return array with objects used to pass as body for calls
export const resolveFilters = (filterArray) => {
	const filters = [];
	filterArray.filter(a => typeof a !== 'undefined').forEach(filterData => {
		const targetData = filterData.data ?? filterData;
		if (filterData instanceof Array) {
			filters.push(filterData);
		} else if (targetData.hasOwnProperty("filter_json")
			&& targetData.filter_json instanceof Array
		) {
			filters.push(targetData.filter_json);
		} else if (targetData.hasOwnProperty("filter_json")) {
			const filterJson = JSON.parse(targetData.filter_json);
			if (filterJson.hasOwnProperty("filters") && filterJson.filters instanceof Array) {
				filterJson.filters.forEach(function (filter) {
					filters.push(JSON.stringify(filter));
				});
			}
		} else if (typeof filterData === 'string') {
			filters.push(filterData);
		}
	});
	return filters;
};

// Format app filters correctly
export const resolveAppFilters = (filters = null) => {
	return new Promise((resolve, reject) => {


		let failed_resolving = false;
		const promises = [];

		filters && filters.forEach((filter, index) => {
			const target = filter[1];
			// Make sure we target the correct key
			target.value = target.v ?? target.value;
			delete target.v;
			// Make sure it is a array to work with
			if (!Array.isArray(target.value)) {
				target.value = [filter.value];
			}
			target.value.map(a => `${a}`).forEach((value, index) => {
				if (value.startsWith("%") && value.endsWith("%")) {
					// Replace % with our value
					const tmpValue = value.replace(/%/g, "").replace(/%/g, "");
					const tmpParts = tmpValue.split(".");
					const tmpKey = tmpParts[tmpParts.length - 1];
					const tmpParent = tmpParts.length > 1 ? tmpParts[0] : null;
					// console.log("tmpValue", tmpValue);
					// console.log("tmpParts", tmpParts);
					// console.log("tmpKey", tmpKey);
					// console.log("tmpParent", tmpParent);
					if (tmpParent && !portalStore.fetching.includes(tmpParent)) {
						portalStore.fetching.push(tmpParent);
						// Listen for changes
						fetchRelated(tmpParent);
						promises.push(new Promise(res => {
							let promiseWatcher;
							promiseWatcher = watch(
								() => portalStore[tmpParent],
								(parent_object) => {
									if (!parent_object) return;
									// Restart our current function based on new input
									resolveAppFilters(filters).then(res);
									promiseWatcher && promiseWatcher();
								},
								{
									immediate: true
								}
							);
						}));
					}

					const newValue = resolveValueParts(tmpKey, tmpParent);
					// console.log("newValue", newValue, tmpKey, tmpParent);
					if (!newValue) {
						failed_resolving = true;
						return;
					}
					target.value[index] = newValue;
				}
			});
		});

		if (!promises.length) {
			if (!failed_resolving) {
				return resolve(filters);
			} else {
				return resolveAppFilters(filters).then(resolve);
			}
		} else {
			Promise.all(promises).then(() => {
				// console.log(" promised all ", filters);
				resolve(filters);
			});
		}
	});
};

// Format the related filters correctly
export const resolveRelatedFilters = (related_filter_json) => {
	const filters = [];
	const relatedFilterJson = JSON.parse(related_filter_json ?? "{}");
	relatedFilterJson?.filters?.forEach(filter => {
		filters.push(JSON.stringify(filter));
	});
	return filters;
};
