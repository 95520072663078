<template>
    <div class="VuePopup PortalPopup" :class="classlist">
        <div class="inner">
            <slot />
            <button v-if="this.closeButton" class="closebtn" @click="togglePopup()">
                <Icon name="close"></Icon>
            </button>
        </div>
        <div class="bg" @click="this.closeByBackground && togglePopup()"></div>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import Icon from "components/icon.vue";

export default defineComponent({
    name: "Popup",
    props: {
        togglePopup : {
            required: true,
        },
        classlist: {
            type: String,
            required: false,
            default: ""
        },
        closeButton: {
            type: Boolean,
            required: false,
            default: true
        },
        closeByBackground: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    components: {
        Icon
    },
    setup() {
        return {}
    }
});
</script>
