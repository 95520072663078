<template>
    <div class="element" :data-required="this.required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <ul class="block-grid one-up filelist">
            <li v-for="upload in componentData.uploads[this.name]">
                <div class="upload">
                    <span class="title" :title="upload.filename">{{ upload.filename }}</span>
                    <Icon name="view" />
                    <span class="preview"><img width="100" :src="upload.url"></span>
                </div>
            </li>
        </ul>
        <uploadElement :readonly="!this.editing || this.readonly" :module_object="this.module_object" :item_id="this.id" @update-upload="updateUpload"/>
        <input
            type="hidden"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
            :autocomplete="this.autocomplete"
            :maxlength="this.maxlength"
            v-model="input"
            @keyup="validate"
            @blur="validate"
        />
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {reactive, ref, watch} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Helper functions
import { postDataAsync, getDataAsync } from "helpers/_api";
import { resolveData } from "helpers/_data";
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

// Our components
import Icon from "components/icon.vue";

import uploadElement from "formbuilder/elements/UploadElement.vue";
import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        Icon,
        uploadElement,
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();
        registerValidator(input, props);

        const componentData = reactive({
            uploads: {}
        });
        const getUpload = () => {
            if (!props.module_object) return;
            // getDataAsync(`/api/v1.0/upload?limit=1&seq=seq&order=desc`, [
            //     [
            //         "RelatedFilter",
            //         {
            //             "linked_ids": [props.id],
            //             "match_all": false,
            //             "module": props.module_object,
            //             "module_link": "Upload"
            //         }
            //     ],
            //     [
            //         "FieldFilter",
            //         {
            //             "column": "state",
            //             "value": "Online"
            //         }
            //     ]
            // ]).then(result => {
            //     console.log("result",result);
            //     componentData.upload = resolveData(result);
            //     if (componentData.upload.length) {
            //         input.value = [...componentData.upload].pop().extra?.filename;
            //     }
            // });
        };

        const uploadRef = ref(1);
        const updateUpload = (tus_result, result) => {
            const ids = result.map(a => ({image_id: a.data.id}));
            componentData.uploads[props.name] = [];
            tus_result.successful.forEach(upload => {
                componentData.uploads[props.name].push({
                    url: upload.preview,
                    filename: upload.meta.name
                });
            })
            input.value = JSON.stringify(ids);
            uploadRef.value += 1;
        };

        const resolveFilename = (filename) => {
            return filename.replace(/^.*[\\\/]/, '');
        }

        // watch(
        //     () => props.value,
        //     () => {
        //         input.value = props.value;
        //     },
        //     {
        //         immediate: true
        //     }
        // );
        if (props.value && (props.name in props.value)) {
            componentData.uploads[props.name] = [];

            const getPromises = [];
            // tus_result.successful.forEach(upload => {
            //     const tmpPromise = new Promise(resolve => {
            //         getDataAsync(upload.uploadURL).then(upload_result => {
            //             resolve(upload_result);
            //         });
            //     });
            //     getPromises.push(tmpPromise);
            // });

            props.value[props.name].forEach(upload => {
                componentData.uploads[props.name].push({
                    url: upload,
                    filename: resolveFilename(upload)
                });
            });
        }

        const placeholder = computed(() => props.translate ? trans(props.placeholder) : props.placeholder);

        return {
            componentData,
            input,
            trans,
            error,
            resolveProps,
            validate,
            updateUpload,
            placeholder
        };
    },
}
</script>