<template>
    <div class="element" :data-required="this.required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <uploadElement :readonly="!this.editing || this.readonly" :module_object="this.module_object" :item_id="this.id" @update-upload="updateUpload"/>
        <input
            type="hidden"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :placeholder="`${this.show_placeholder ? placeholder + (required ? ' *' : '') : ''}`"
            :autocomplete="this.autocomplete"
            :maxlength="this.maxlength"
            v-model="input"
            @keyup="validate"
            @blur="validate"
        />
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import {reactive, ref, watch} from "vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Helper functions
import { postDataAsync, getDataAsync } from "helpers/_api";
import { resolveData } from "helpers/_data";
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

// Our components
import uploadElement from "formbuilder/elements/UploadElement.vue";
import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        uploadElement,
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();
        registerValidator(input, props);

        const componentData = reactive({
            "image": []
        });
        const getImage = () => {
            getDataAsync(`/api/v1.0/upload?limit=1&seq=seq&order=desc`, [
                [
                    "RelatedFilter",
                    {
                        "linked_ids": [props.id],
                        "match_all": false,
                        "module": props.module_object,
                        "module_link": "Upload"
                    }
                ],
                [
                    "FieldFilter",
                    {
                        "column": "state",
                        "value": "Online"
                    }
                ]
            ]).then(result => {
                componentData.image = resolveData(result);
                if (componentData.image.length) {
                    input.value = componentData.image.pop().extra?.filename;
                }
            });
        };

        const uploadRef = ref(1);
        const updateUpload = () => {
            uploadRef.value += 1;
        };
        watch(
            () => uploadRef.value,
            () => {
                getImage();
            }
        );

        watch(
            () => props.value,
            () => {
                input.value = props.value;
            },
            {
                immediate: true
            }
        );

        const placeholder = computed(() => props.translate ? trans(props.placeholder) : props.placeholder);

        return {
            trans,
            input,
            error,
            resolveProps,
            validate,
            updateUpload,
            placeholder
        };
    },
}
</script>