
<template>
    <template v-if="viewData.config.settings?.target">
        <template v-if="viewData.workflowcard_id">
            <div class="row tabrow">
                <div class="col-sm-8 tabcolumn">
                    <ul class="block-grid tabs">
                        <li v-for="tab in tabs('maincolumn')" :class="{ active: tab_active(tab.property.type, tab.property.id) }" @click.prevent="handleTab(tab.property.type, tab.property.id)">
                            <span class="title">{{ trans(tab.label) }}</span>
                        </li>
                    </ul>
                    <tab-overview :tabs="tabs('maincolumn')" :hide_heading="true" />
                </div>
                <div class="col-sm-4 sidecolumn">
                    <tab-overview :tabs="tabs('sidecolumn')" :always_open="true" />
                </div>
            </div>

        </template>
        <template v-else-if="viewData.workflowphase_id">

            <workflowphase-list
                :workflowboard_id="viewData.workflowboard_id"
                :workflowphase_id="viewData.workflowphase_id"
                :related_filter_json="viewData.extra_filters"
                :key="viewData.overview_ticker"
            ></workflowphase-list>

        </template>
        <template v-else>

            <workflowphase-overview
                v-if="viewData.workflowboard_id"
                :layout="viewData.layout"
                :workflowboard_id="viewData.workflowboard_id"
                :workflowphase_id="viewData.workflowphase_id"
                :related_filter_json="viewData.extra_filters"
            ></workflowphase-overview>

        </template>
    </template>
    <template v-else>
        {{ route.meta.module }} {{ route.meta.action }}<br>
        {{ viewData.type }}<br>
    </template>
</template>

<script>
import {onMounted, watch, defineComponent, inject, computed, reactive, provide} from "vue";
import { useRoute } from "vue-router";

import tabOverview from "view/dashboard/includes/tab-overview";

import { workflowproperties, workflowpropertiesbyfieldgroup } from "store/workflow";

import Icon from "components/icon";
import trans from "helpers/_translation";

import workflowphaseList from "view/dashboard/includes/workflowphase-list";
import workflowphaseOverview from "view/dashboard/includes/workflowphase-overview";

export default defineComponent({
    name: "part",
    components: {
        Icon,

        tabOverview,

        workflowphaseList,
        workflowphaseOverview
    },
    setup() {
        const route = useRoute();
        const viewData = inject("viewData");

        const handleTab = inject("handleTab");
        const tabs = inject("tabs");
        const tab_editable = inject("tab_editable");
        const tab_active = inject("tab_active");

        return {
            trans,
            route,

            viewData,
            workflowproperties,
            workflowpropertiesbyfieldgroup,

            tabs,
            tab_active,
            tab_editable,
            handleTab
        }
    }
});
</script>
<script setup>
</script>