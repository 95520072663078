<template>
    <div class="state-outer" :class="value">
        <div class="state-inner">
            <span class="state"></span>
            <span class="state-title">{{ value }}</span>
        </div>
    </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from "vue";

export default defineComponent({
    name: "state",
    props: {
        value: {
            type: String,
            required: true,
            default: ""
        }
    },
    setup() {

        return {

        };
    },
});
</script>

<style>
.state-outer {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    margin-bottom: 12px;
    .state-inner {
        padding: 7px 8px;
        border-radius: 11px;
        margin:0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: auto;
        position: relative;
        overflow: hidden;
        width: auto;
        clear: both;
        &::before {
             content: '';
             position: absolute;
             left: 0;
             top: 0;
             right: 0;
             bottom: 0;
             background: var(--state, #37db87);
             opacity: 0.1;
         }
    }
}
.state-title {
    text-transform: uppercase;
    line-height: 1;
    letter-spacing: 0.1em;
    font-size: 9px;
}
.state {
    font-family: "FontAwesome";
    width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    color: #fff;
    background:var(--state,#37db87);
    margin-right:5px;
    &::before {
         font-size: 10px;
         line-height: 1;
         position: absolute;
         left: 50%;
         top: 50%;
         width: 10px;
         text-align: center;
         transform: translate(-50%, -50%);
     }
}
</style>