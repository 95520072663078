<template>
    <div class="json-table">
        <table v-if="isArrayOfObjects(data)">
            <thead>
            <tr>
                <!-- Generate headers dynamically from object keys -->
                <th v-for="header in headers" :key="header">{{ header }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, index) in data" :key="index">
                <!-- Render each value corresponding to the headers -->
                <td v-for="header in headers" :key="header">
                    <template v-if="isObject(item[header])">
                        <JsonTable :data="item[header]" />
                    </template>
                    <template v-else-if="Array.isArray(item[header])">
                        <JsonTable :data="arrayToObject(item[header])" />
                    </template>
                    <template v-else>
                        {{ item[header] }}
                    </template>
                </td>
            </tr>
            </tbody>
        </table>
        <div v-else>
            <JsonTable :data="data" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'JsonTable',
    props: {
        data: {
            type: [Object, Array],
            required: true,
        },
    },
    computed: {
        headers() {
            // Extract headers if data is an array of objects
            if (Array.isArray(this.data) && this.data.length > 0 && this.isObject(this.data[0])) {
                return Object.keys(this.data[0]);
            }
            return [];
        },
    },
    methods: {
        isObject(value) {
            return value && typeof value === 'object' && !Array.isArray(value);
        },
        arrayToObject(array) {
            // Convert arrays into objects for consistent rendering
            return array.reduce((acc, val, index) => {
                acc[index] = val;
                return acc;
            }, {});
        },
        isArrayOfObjects(array) {
            // Check if the array is an array of objects
            return (
                Array.isArray(array) &&
                array.length > 0 &&
                array.every((item) => this.isObject(item))
            );
        },
    }
};
</script>

<style scoped>
.json-table {
    overflow: auto;
    font-size: 14px;
    background-color: #2e3440;
    color: #d8dee9;
}

.json-table table {
    width: 100%;
    border-collapse: collapse;
}

.json-table th {
    white-space:nowrap;
}
.json-table th,
.json-table td {
    padding: 8px;
    border: 1px solid #444c56;
}

.json-table th {
    background-color: #3b4252;
    color: #eceff4;
}

td {
    vertical-align: top;
    word-break: break-all;
}
</style>
