<template>
    <nav class="mainnav">
        <div class="logocontainer" v-if="isWorkflowportal && isMobile">
            <Logo />
        </div>
        <Profile v-if="isWorkflowportal" />
        <div class="top">
            <template v-for="navigationgroup in filter_list(navigationgroupList)">
                <div class="navGroup">
                <span class="nav-header" v-if="navigationgroup.title">
                    <h3>{{ trans(navigationgroup.title) }}</h3>
                </span>
                <ul class="nav navbar-nav">
                    <li v-for="button in filter_buttons(navigationgroup.data)">
                        <button :class="{ active: active(button.name) }" @click.prevent="handleClick(button.name)">
                            <Icon :name="button.icon" />
                            <span class="title">{{ trans(button.title) }}</span>
                        </button>
                    </li>
                </ul>
                </div>
            </template>
        </div>
        <div class="bottom" v-if="isWorkflowportal">
            <button type="button" @click.prevent="toggleMode">
                <Icon name="darkmode" />
                <span class="title">{{ textMode }}</span>
            </button>
            <button type="button" @click="handleLogout">
                <Icon name="logout" />
                <span class="title">{{ trans("Uitloggen") }}</span>
            </button>
        </div>
    </nav>
</template>

<script>
import {defineComponent, provide, ref, computed, inject} from "vue";
import { useRouter, useRoute } from "vue-router";

// Components
import Icon from "components/icon";

// Elements
import Logo from "elements/logo";
import Profile from "elements/profile";

// Helpers
import trans from "helpers/_translation";
import { isMobile } from "helpers/_device";

import { env } from "config";
import { contact, value, unauthorized } from "store/portal";
import { view_visible } from "store/view";
import { getSetting, storeSetting, matchSetting } from 'store/settings';

import { clearCache } from "helpers/_cache";

// Services
import { destroyTokens } from "services/JwtService";

export default defineComponent({
    name: "navigationElement",
    components: {
        Logo,
        Icon,
        Profile
    },
    setup() {
        const isWorkflowportal = inject("isWorkflowportal");

        const route = useRoute();
        const router = useRouter();

        const navigationgroupList = computed(() => {
            return env("navigationgroup");
        });

        const handleLogout = () => {
            if (!isWorkflowportal) return;
            destroyTokens();
            clearCache();
            router.push("/login");
            setTimeout(() => {
                location.reload();
            }, 100);
        };

        const toggleMode = () => {
            const newValue = matchSetting("layout", "mode", "light") ? "dark" : "light";
            storeSetting("layout", "mode", newValue);
        };
        provide("toggleMode", toggleMode);

        const textMode = computed(() => {
            return matchSetting("layout", "mode", "light") ? trans('Donkere modus') : trans('Lichte modus');
        });

        // Button related code
        const active = (name) => {
            return computed(() => {
                return route.name === name;
            }).value;
        };
        const handleClick = (name, query = {}) => {
            if (unauthorized.value) return;
            router.push({
                name: name,
                query: query
            });
        };
        provide("handleClick", handleClick);


        const filter_buttons = (buttons) => {
            return computed(() => {
                return buttons.filter(a => view_visible(a.name));
            }).value;
        };
        const filter_list = (list) => {
            return computed(() => {
                if (!list) return [];
                return list.filter(a => filter_buttons(a.data).length > 0);
            }).value;
        };

        return {
            trans,

            isWorkflowportal,
            env,
            value,
            contact,

            isMobile,

            navigationgroupList,

            handleLogout,
            handleClick,

            active,

            filter_list,
            filter_buttons,
            
            toggleMode,
            textMode,
        };
    },
});
</script>