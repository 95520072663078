<template>
    <div class="element JsonField" :data-required="this.required" :class="error.error ? 'error' : ''">
        <label :for="`field_${this.id}`">{{ this.label }}</label>
        <input
            class="input"
            type="hidden"
            :id="`field_${this.id}`"
            :name="this.name"
            :readonly="!this.editing || this.readonly"
            :placeholder="this.placeholder"
            :autocomplete="this.autocomplete"
            :maxlength="this.maxlength"
            v-model="input"
        />
        <template v-if="!json">
            {{ trans("Geen waardes beschikbaar") }}
        </template>
        <template v-if="!this.editing || this.readonly">
            <!-- <pre class="input" v-html="highlightedJson"></pre> -->
            <JsonTable v-if="json" :data="json" />
        </template>
        <template v-else>
            <JsonEditor v-if="json" :data="json" :path="[]" @update="updateValue" />
        </template>
        <div v-if="error.error" class="error">{{ trans(error.message) }}</div>
    </div>
</template>

<style lang="scss">
.JsonField pre.input {
    overflow: auto; /* Adds scrollbars if content overflows */
    white-space: pre-wrap; /* Wraps text within the container */
    word-wrap: break-word; /* Breaks long words to fit the container */
    background-color: #2e3440; /* Dark background */
    color: #d8dee9; /* Default text color */
    font-size: 14px;

    .json-key {
        color: #81a1c1; /* Light blue for keys */
    }

    .json-string {
        color: #a3be8c; /* Green for strings */
    }

    .json-number {
        color: #b48ead; /* Purple for numbers */
    }

    .json-boolean {
        color: #d08770; /* Orange for booleans */
    }
}
</style>


<script>
import {ref, watch, computed } from "vue";

// Our form helpers
import useFormValidation from "./_validation";
import { propDefaults } from "./_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

import JsonTable from "./JsonFieldTable.vue";
import JsonEditor from "./JsonFieldEditor.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {

        }
    ),
    components: {
        JsonTable,
        JsonEditor
    },
    setup(props) {
        let input = ref("");
        const {validateTextField, error} = useFormValidation();
        const validateInput = () => {
            if (props.required) {
                validateTextField(props.label, input.value);
            }
        };

        const json = computed(() => {
            try {
                return JSON.parse(input.value);
            } catch (e) {
                return null;
            }
        });
        const highlightedJson = computed(() => {
            let target = json.value;
            if (typeof target !== "string") {
                target = JSON.stringify(target, null, 2);
            }
            return target
                .replace(/"([^"]+)":/g, '<span class="json-key">"$1"</span>:') // Format keys
                .replace(/: "([^"]+)"/g, ': <span class="json-string">"$1"</span>') // Format string values
                .replace(/: (\d+)/g, ': <span class="json-number">$1</span>') // Format numeric values
                .replace(/: (true|false|null)/g, ': <span class="json-boolean">$1</span>'); // Format boolean and null values
        });

        // Function to handle updates passed from the JsonEditor component
        const updateValue = (path, value) => {
            const tmpJson = JSON.parse(JSON.stringify(json.value));
            let target = tmpJson;
            for (let i = 0; i < path.length - 1; i++) {
                target = target[path[i]];
            }
            target[path[path.length - 1]] = value;

            input.value = JSON.stringify(tmpJson);
        };

        watch(
            () => props.id,
            () => {
                input.value = JSON.stringify(props.value);
            },
            {
                immediate: true
            }
        );

        return {
            input,
            error,
            validateInput,
            trans,

            json,
            highlightedJson,

            updateValue
        };
    },
}
</script>