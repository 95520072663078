<template>
    <template v-for="tab in tabs">
        <template v-if="this.always_open || tab_active(tab.property.type, tab.property.id)">
            <template v-if="tab.property.type === 'extra'">
                <div class="inner" v-if="tab.property.id === 'files'">
                    <div class="Heading" v-if="!this.hide_heading">
                        <h3 class="h3">{{ trans(tab.label) }}</h3>
                    </div>
                    <file-overview module_object="Workflowcard" :item_id="viewData.workflowcard_id" :updatekey="uploadRef" />
                    <file-upload id="documents" :readonly="false" module_object="Workflowcard" :item_id="viewData.workflowcard_id" :allowed_file_types="allowed_file_types" @update-upload="updateUpload"/>
                </div>
                <div class="inner" v-if="tab.property.id === 'reactions'">
                    <div class="Heading" v-if="!this.hide_heading">
                        <h3 class="h3">{{ trans(tab.label) }}</h3>
                    </div>
                    <message-overview module_object="Workflowcard" :item_id="viewData.workflowcard_id" />
                </div>
                <div class="inner" v-if="tab.property.id === 'historylog'">
                    <div class="Heading" v-if="!this.hide_heading">
                        <h3 class="h3">{{ trans(tab.label) }}</h3>
                    </div>
                    <historylog-overview module_object="Workflowcard" :item_id="viewData.workflowcard_id" />
                </div>
            </template>
            <template v-else-if="tab.property.type === 'overview'">
                <workflowlayoutcard-overview :data="tab.data" />
            </template>
            <template v-else>
                <form class="cs_form" @submit.prevent="handleSubmitEvent($event, tab.property.type, tab.property.id)">
                    <div class="inner">
                        <div class="Heading" v-if="!this.hide_heading">
                            <h3 class="h3">{{ trans(tab.label) }}</h3>
                        </div>

                        <fieldset v-if="!fieldsets_visible(tab.property.id)">
                            {{ trans("Momenteel is er geen data beschikbaar.") }}
                        </fieldset>
                        <fieldset v-if="workflowpropertiesbyfieldgroup(tab.property.id).length && fieldset_visible(tab.property.id, viewData.workflowcard_id)">
                        <Fields
                            :editing="tab_editable(tab.property.type, tab.property.id)"
                            :fields="workflowpropertiesbyfieldgroup(tab.property.id)"
                            :values="workflowproperties(viewData.workflowcard_id)"
                            module_object="Workflowpropertyfield"
                        />
                        </fieldset>
                        <template v-for="fieldgroup in workflowpropertyfieldgroupsbyfieldgroupid(tab.property.id)">
                            <fieldset v-if="workflowpropertiesbyfieldgroup(fieldgroup.id).length && fieldset_visible(fieldgroup.id, viewData.workflowcard_id)">
                                <legend>{{ fieldgroup.title }}</legend>
                                <Fields
                                    :editing="tab_editable(tab.property.type, tab.property.id)"
                                    :fields="workflowpropertiesbyfieldgroup(fieldgroup.id)"
                                    :values="workflowproperties(viewData.workflowcard_id)"
                                    module_object="Workflowpropertyfield"
                                />
                            </fieldset>
                        </template>
                        <nav v-if="tab_editable(tab.property.type, tab.property.id)">
                            <button type="submit" class="more">
                                <span class="title">{{ trans("Opslaan") }}</span>
                            </button>
                        </nav>
                    </div>
                </form>
            </template>
        </template>
    </template>
</template>

<script>
import { defineComponent, computed, onMounted, reactive, inject, watch, ref } from "vue";

import Icon from "components/icon";
import trans from "helpers/_translation";

import { workflowproperties, workflowpropertiesbyfieldgroup, workflowpropertyfieldgroupsbyfieldgroupid } from "store/workflow";

import Fields from "components/fields";

import workflowphaseList from "view/dashboard/includes/workflowphase-list";
import workflowphaseOverview from "view/dashboard/includes/workflowphase-overview";
import workflowlayoutcardOverview from "view/dashboard/includes/workflowlayoutcard-overview";

import FileOverview from "components/file-overview";
import FileUpload from "components/file-upload";
import HistorylogOverview from "components/historylog-overview";
import MessageOverview from "components/message-overview";

// Helpers
import { addHistorylogRecord } from "helpers/_historylog";
import { setWorkflowpropertyfield }  from "helpers/_workflowpropertyfield";

// Config
import { env } from "config";

// Store
import {workflowpropertyByName} from "store/workflow";
import { contact, value, usergroup_ids } from "store/portal";

import { addNotification } from "store/notifications";


export default defineComponent({
    name: "tab-overview",
    props: {
        tabs: {
            type: Object,
            required: true
        },
        always_open: {
            type: Boolean,
            required: false,
            default: false
        },
        hide_heading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        Icon,

        workflowphaseList,
        workflowphaseOverview,
        workflowlayoutcardOverview,

        Fields,
        FileOverview,
        FileUpload,
        HistorylogOverview,
        MessageOverview
    },
    setup(props) {
        const viewData = inject("viewData");

        const tabs = computed(() => props.tabs);
        const tab_editable = inject("tab_editable");
        const tab_active = inject("tab_active");

        const allowed_file_types = [
            "image/psd",
            "image/bmp",
            "image/gif",
            "image/jpeg",
            "image/png",
            "image/tiff",
            "application/postscript",
            "application/pdf",
            "application/rtf",
            "application/octet-stream",
            "application/x-compressed",
            "application/zip",
            "text/plain",
            "text/xml",
            "text/vcard",
            "video/webm",
            "video/mp4",
            "video/ogg",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
            "application/vnd.ms-word.document.macroEnabled.12",
            "application/vnd.ms-word.template.macroEnabled.12",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
            "application/vnd.ms-excel.sheet.macroEnabled.12",
            "application/vnd.ms-excel.template.macroEnabled.12",
            "application/vnd.ms-excel.addin.macroEnabled.12",
            "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
            "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            "application/vnd.openxmlformats-officedocument.presentationml.template",
            "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
            "application/vnd.ms-powerpoint.addin.macroEnabled.12",
            "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
            "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
            "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
        ].join(",");

        const uploadRef = ref(1);
        const updateUpload = () => {
            uploadRef.value += 1;
        };

        const handleSubmitEvent = (e, tab_type, tab_id) => {
            if (!tab_editable(tab_type, tab_id)) {
                addNotification(trans("U heeft geen rechten tot bewerken."), 3500);
                return;
            }
            handleSubmit(e.target);
        };
        // Our handling
        const handleSubmit = (form) => {
            // Our data
            const formData = new FormData(form);

            // Keep track of changes, so we only add historylog when needed
            let changes = false;

            const setValue = (name, value) => {
                // Get our property field we submitted
                if (name === "files[]") return;
                const field = workflowpropertyByName(name);
                // Set field true if changes made, otherwise fallback to current state
                changes = setWorkflowpropertyfield(viewData.item.data, viewData.workflowcard_id, field, value) || changes;
            };

            const filledKeys = [...new Set(Array.from(formData.keys()))];
            filledKeys.forEach(key => {
                const value = formData.getAll(key);
                setValue(key, value);
            });
            const allFields = [];
            Array.from(form.querySelectorAll("input[name], textarea[name]")).forEach(input => {
                if (filledKeys.includes(input.name) || allFields.includes(input.name)) return;
                setValue(input.name, "");
                allFields.push(input.name);
            });

            if (changes) {
                // Store a historylog item
                const fullname = value(contact.value, env("variables.contact.properties.fullname") );
                if (fullname) {
                    addHistorylogRecord("Workflowcard", viewData.workflowcard_id,
                        trans("#fullname# heeft dit item bewerkt."),
                        " ",
                        {
                            fullname: fullname
                        }
                    );
                } else {
                    addHistorylogRecord("Workflowcard", viewData.workflowcard_id,
                        trans("Dit item is bewerkt")
                    );
                }
                addNotification(trans("De wijzigingen zijn succesvol opgeslagen."), 3500);
            } else {
                addNotification(trans("Er zijn geen wijzigingen aangetroffen."), 3500);
            }
        };


        const fieldset_visible = (fieldset_id, workflowcard_id) => {
            return computed(() => {
                if (viewData.config.settings?.fieldset?.[`${fieldset_id}`]?.hide_if_empty) {
                    const fields = workflowpropertiesbyfieldgroup(fieldset_id);
                    const values = workflowproperties(workflowcard_id);
                    return fields.some(field => values.value[`${field.id}`]?.value);
                }
                return true;
            }).value;
        };

        const fieldsets_visible = (tab_id) => {
            return computed(() => {
                const fieldset_ids = [tab_id, ...workflowpropertyfieldgroupsbyfieldgroupid(tab_id).map(a => a.id)];
                return fieldset_ids.some(id => fieldset_visible(id, viewData.workflowcard_id));
            }).value;
        };

        return {
            trans,

            viewData,
            uploadRef,
            workflowproperties,
            workflowpropertiesbyfieldgroup,
            workflowpropertyfieldgroupsbyfieldgroupid,

            allowed_file_types,

            handleSubmitEvent,
            handleSubmit,

            tabs,
            tab_editable,
            tab_active,

            fieldset_visible,
            fieldsets_visible,

            updateUpload
        };
    },
});
</script>
<script setup>
</script>