<template>
    <div class="element" :data-required="required" :class="{ [this.type] : true, error : error.error }">
        <labelElement v-bind="resolveProps('label', this)" :error="error" />
        <placeholderElement v-bind="resolveProps('placeholder', this)" :error="error" :value="this.input" />
        <stateElement v-bind="resolveProps('state', this)" :error="error" />
        <div class="input-group">
            <input
                class="form-control input"
                type="text"
                value=""
                :readonly="!this.editing || this.readonly"
                ref="searchRef"
                autocomplete="off"
                @focus="handleFocus"
                @keydown="handleKey"
                @blur="handleSearch($event) && handleBlur($event)"
            />
            <div class="input-group-append">
                <button type="button" class="more btn" @click.prevent="handleSearch" :disabled="!this.editing || this.readonly">
                    <Icon name="search" />
                </button>
            </div>
        </div>
        <select
            v-if="componentData.search_value && componentData.search_open"
            :id="`field_${this.id}`"
            class="input"
            :readonly="!this.editing || this.readonly"
            :autocomplete="this.autocomplete"
            :disabled="!input && !Object.keys(componentData.options).length"
            @blur="validate"
            @change="handleSelect"
        >
            <option v-if="!Object.keys(componentData.options).length" value="" selected>{{ trans("Geen resultaten gevonden") }}</option>
            <option v-else value="">{{ trans("- Maak uw keuze -") }}</option>
            <template v-for="(label, id) in componentData.options">
                <option :value="id" :selected="input == id">{{ this.translate ? trans(label) : label }}</option>
            </template>
        </select>
        <div class="note" v-if="selection">
            <label>{{ trans("Uw keuze") }}:</label>
            <var>{{ selection }}</var>
        </div>
        <input type="hidden" v-model="input" :name="this.name" />
        <errorElement v-bind="resolveProps('error', this)" :error="error" />
        <tooltipElement v-bind="resolveProps('tooltip', this)" :inline="true" />
    </div>
</template>

<script>
import { ref, watch, reactive, computed } from "vue";

import Icon from "components/icon.vue";

// Our form helpers
import useFieldValidation from "formbuilder/fields/_validation";
import { propDefaults, resolveProps } from "formbuilder/fields/_props";

// Our helpers
import { mergeDeep } from "helpers/_objects";
import trans from "helpers/_translation";

import labelElement from "formbuilder/elements/LabelElement.vue";
import stateElement from "formbuilder/elements/StateElement.vue";
import placeholderElement from "formbuilder/elements/PlaceholderElement.vue";
import errorElement from "formbuilder/elements/ErrorElement.vue";
import tooltipElement from "formbuilder/elements/TooltipElement.vue";

export default {
    props: mergeDeep(
        propDefaults,
        {
            show_none_option: {type: Boolean, required: false, default:false}
        }
    ),
    components: {
        Icon,
        labelElement,
        stateElement,
        placeholderElement,
        errorElement,
        tooltipElement
    },
    setup(props, { emit }) {
        const input = ref("");
        const {registerValidator, validate, error} = useFieldValidation();

        const componentData = reactive({
            options: {},
            all_options: {},
            search_open: false,
            search_value: ""
        });

        const filterOptions = (value, options) => {
            return Object.keys(options).filter(key => {
                if (value.length < 2) return false;
                return options[key].toLowerCase().includes(value.toLowerCase());
            }).reduce((cur, key) => {
                return Object.assign(cur, { [key]: options[key] });
            }, {});
        }
        const resolveOptions = (options) => {
            if (!props.value && !props.required) {
                options = {
                    "": `- Selecteer een ${props.label} -`,
                    ...options
                };
            }
            return options;
        };
        watch(
            () => props.options,
            (options) => {
                componentData.all_options = resolveOptions(options);
            },
            {
                immediate: true,
                deep: true
            }
        );

        watch(
            () => props.value,
            () => {
                input.value = props.value ?? Object.keys(componentData.all_options)[0] ?? "";
            },
            {
                immediate: true
            }
        );


        const required = computed(() => props.required);
        watch(
            () => props.required,
            () => {
                registerValidator(input, props);
            },
            {
                immediate: true
            }
        );

        // If we got a initial value, validate instantly
        props.value && validate();

        const searchRef = ref();
        const handleKey = (e) => {
            if (e.key === "Enter") {
                handleSearch();
            }
        }
        const handleFocus = (e) => {
            componentData.search_open = true;
        }
        const handleSelect = (e) => {
            const target = e.currentTarget || e.target;
            if (!target.value) return;
            input.value = target.value;
            componentData.search_open = false;
        }
        const handleSearch = (e) => {
            componentData.search_value = searchRef.value.value;
            componentData.options = filterOptions(componentData.search_value, componentData.all_options);
        }

        const selection = computed(() => {
            if (!input.value) return "";
            return componentData.all_options[input.value];
        });

        return {
            trans,
            input,
            error,
            componentData,
            resolveProps,
            validate,
            required,

            searchRef,
            handleKey,
            handleSearch,
            handleFocus,
            handleSelect,
            filterOptions,

            selection
        };
    },
}
</script>

<style lang="scss">
.element.ListField {
    .input-group + select {
        margin-top:10px;
    }
    select[disabled] {
        opacity:0.5;
    }
    .note {
        margin-top:5px;
        font-size:13px;
        label { margin-right:5px; opacity:0.7; }
        var { font-style:normal; }
    }
}
</style>